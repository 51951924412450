import { screenBreakPoint } from "@styles/styleVariables";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { MembersPortalSection } from "./membersPortalSection";
import { motion } from "framer-motion";
import { parentAnimation } from "@styles/animations";

export const MembersPortalContent = () => {
  const query = useStaticQuery(graphql`
    query membersPortalInformationQuery {
      membersPortalInformationQuery: allStrapiMembersPortalInformation(
        sort: { fields: rank, order: ASC }
      ) {
        nodes {
          briefDescription
          strapi_id
          name
          useTemplate
          rank
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
          sublink
        }
      }
    }
  `);

  const membersPortalInformationQuery =
    query.membersPortalInformationQuery.nodes;

  return (
    <SMembersPortalContent
      initial="before"
      whileInView="after"
      variants={parentAnimation(0.1)}
    >
      {membersPortalInformationQuery.map((mp: any) => (
        <MembersPortalSection
          name={mp?.name ?? ""}
          briefDescription={mp?.briefDescription ?? ""}
          strapiId={mp?.strapi_id ?? ""}
          coverImage={
            mp?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""
          }
          url={`${mp.sublink ?? ""}`}
          buttonName={mp?.name ?? ""}
          useTemplate={mp?.useTemplate ?? false}
          rank={mp?.rank ?? "9999"}
        ></MembersPortalSection>
      ))}
    </SMembersPortalContent>
  );
};

const SMembersPortalContent = styled(motion.div)`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem 0rem;

  @media (max-width: ${screenBreakPoint.sm}) {
    flex-direction: column;
  }
`;
