import { IInformationSection } from "@interface/propsInterface";
import {
  color,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pushItemUp } from "@styles/animations";

export const MembersPortalSection = ({
  name,
  briefDescription,
  strapiId,
  coverImage,
  url,
}: IInformationSection) => {
  return (
    <SMembersPortalCard
      to={url}
      whileInView="after"
      initial="before"
      variants={pushItemUp}
    >
      <div className="members-portal-card-content">
        <p className="members-portal-card-title">{name ?? ""}</p>
      </div>
      <div className="members-portal-card-image-wrapper">
        <GatsbyImage
          image={coverImage ?? ""}
          alt={name ?? ""}
          className="members-portal-card-image"
        ></GatsbyImage>
        <div className="members-portal-card-image-overlay"></div>
      </div>
    </SMembersPortalCard>
  );
};

const SMembersPortalCard = styled(motion(Link))`
  margin: 1rem;
  position: relative;
  width: 33%;
  height: 60rem;

  @media (max-width: ${screenBreakPoint.sm}) {
    width: 100%;
    height: 20rem;
  }

  &:hover {
    .members-portal-card-image-wrapper {
      .members-portal-card-image-overlay {
        transition: all 0.2s ease-in-out;
        background: ${convertHexToRGBA(color.prussianBlue, 0.33)};
      }
    }

    .members-portal-card-content {
      .members-portal-card-title {
        transition: all 0.2s ease-in-out;
        color: ${color.sunglow};
      }
    }
  }

  .members-portal-card-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    .members-portal-card-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scaleX(-1);
    }

    .members-portal-card-image-overlay {
      background: ${convertHexToRGBA(color.black, 0.5)};
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .members-portal-card-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;

    .members-portal-card-title {
      text-align: right;
      font-weight: ${fontWeight.medium};
      font-size: ${fontSize.md};
      color: ${color.culture};
    }

    .members-portal-card-number {
      font-weight: ${fontWeight.extraBold};
      font-size: ${fontSize.xxl};
      color: ${color.sunglow};
    }
  }
`;
